import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "split-container py-10" }
const _hoisted_2 = { class: "split-container__bg" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "px-sm-4 px-md-0" }
const _hoisted_5 = { class: "mt-md-0 mt-sm-4 mt-4" }
const _hoisted_6 = { class: "button-container mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, {
      class: "pa-0",
      style: _normalizeStyle(_ctx.max ? 'max-width: 100%' : null)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                class: _normalizeClass([{
              'order-md-2': _ctx.reverseLayout,
              'order-md-1': !_ctx.reverseLayout,
            }, "image-container"]),
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.imageSrc,
                    style: _normalizeStyle(`min-height: ${_ctx.minHeight}`),
                    alt: "Art Club Image"
                  }, null, 12, _hoisted_3)
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_col, {
                class: _normalizeClass([{
              'order-md-1': _ctx.reverseLayout,
              'order-md-2': !_ctx.reverseLayout,
              'pr-4': _ctx.reverseLayout,
              'pl-4': !_ctx.reverseLayout,
            }, "text-container"]),
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.title), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.description), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoList, (item) => {
                      return (_openBlock(), _createElementBlock("p", { key: item }, _toDisplayString(item), 1))
                    }), 128)),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          (button.type !== 'click')
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                color: button.color,
                                class: "schedule-button white--text"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_router_link, {
                                    class: "no-decoration white--text font-weight-bold",
                                    to: button.link
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(button.text), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"])
                                ]),
                                _: 2
                              }, 1032, ["color"]))
                            : (_openBlock(), _createBlock(_component_v_btn, {
                                key: 1,
                                color: button.color,
                                class: "schedule-button white--text font-weight-bold",
                                onClick: _ctx.scrollTo
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(button.text), 1)
                                ]),
                                _: 2
                              }, 1032, ["color", "onClick"]))
                        ], 64))
                      }), 128))
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["style"])
  ]))
}